import classNames from 'classnames';
import React, { useContext, useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { useInView } from 'react-intersection-observer';
import LazyLoad from 'react-lazyload';
import { EventListPageContext } from '../../containers/EventListPage/EventListPage';
import i18n from '../../i18n';
import {
    MY_LIST_KEY_EVENT_OCCURRENCES,
    isPageIdSaved,
    saveOrRemoveId,
} from '../../utils/MyListStorage';
import { ucFirst } from '../../utils/caseconverters';
import { dataLayerPush, trackBanner } from '../../utils/datalayer';
import { DATE_FORMAT, LOCALE, isSameDate } from '../../utils/date';
import EventIcon from '../EventIcon/EventIcon';
import Icon from '../Icon';
import { TrackingContext } from '../StreamField/StreamField';
import styles from './CardEvent.module.scss';

const CardEvent = ({
    id,
    image,
    startDate,
    endDate,
    isLoader = false,
    label,
    title,
    description,
    category,
    categories,
    content,
    onClick,
    address,
    modifiers,
    href,
    size,
    className,
    showRemoveButton,
    venueName,
    removeEvent,
    setActive,
    inSlider,
    tabIndex,
    onCardClick = null,
    startTime,
    endTime,
    noEffect = false,
    fromEventList = false,
    eventListHeadline,
    isLookingForMore = false,
    isSearchResult = false,
    backendModifiers = [],
}) => {
    const [isSaved, setIsSaved] = useState();
    const bannerLevel = useContext(TrackingContext);
    const searchContext = useContext(EventListPageContext);

    let extraProps = {};
    useEffect(() => {
        setIsSaved(isPageIdSaved(MY_LIST_KEY_EVENT_OCCURRENCES, id));
    }, []);

    const { ref, inView } = useInView({
        threshold: 0.2,
        triggerOnce: true,
    });

    if (bannerLevel || onCardClick || isLookingForMore || isSearchResult) {
        extraProps.onClick = () => {
            let formattedCategories = '';

            if (typeof categories === 'string') {
                formattedCategories = categories;
            } else if (categories && categories.length > 0) {
                formattedCategories = categories.join(',');
            }

            if (bannerLevel && !isLookingForMore && !isSearchResult) {
                let extraData = {};
                if (fromEventList) {
                    extraData = {
                        modulHeadline: eventListHeadline,
                    };
                }
                trackBanner(bannerLevel, formattedCategories, title, extraData);
            }

            if (isLookingForMore && !isSearchResult) {
                dataLayerPush({
                    event: 'lookingForMore',
                    bannerCategory: formattedCategories,
                    bannerHeadline: title,
                });
            }

            if (isSearchResult && !isLookingForMore) {
                dataLayerPush({
                    event: 'eventListingClick',
                    eventListingCategory: formattedCategories,
                    bannerHeadline: title,
                    filtercategory:
                        searchContext.state.selectedCategories.join(','),
                });
            }

            if (onCardClick) {
                onCardClick(title, categories);
            }
        };
    }

    const handleSave = () => {
        let isSaved = saveOrRemoveId(
            MY_LIST_KEY_EVENT_OCCURRENCES,
            id,
            title,
            categories
        );
        setIsSaved(isSaved);
    };

    modifiers = [...modifiers, ...backendModifiers];

    if (modifiers.includes('RightCol')) {
        size = 'RightCol';
    }

    const saveButtonText = isSaved
        ? `${i18n.t('generic.saved')}: ${title}`
        : `${i18n.t('generic.save')}: ${title}`;

    const bookmarkIconType = isSaved
        ? { type: 'bookmarkFilled', color: 'pink', hoverColor: 'black800' }
        : { type: 'bookmark', color: 'black800', hoverColor: 'pink' };

    const isHorizontal = content;

    const handleRemoveEvent = (e) => {
        e.preventDefault();
        removeEvent(id);
    };

    const handleMouseEnter = () => {
        setActive(id);
    };
    let iconColor = 'gray100';
    if (isHorizontal || modifiers.includes('White')) {
        iconColor = 'black';
    } else if (modifiers.includes('Black')) {
        iconColor = 'white';
    }


    return (
        <div
            ref={ref}
            onMouseEnter={showRemoveButton ? handleMouseEnter : null}
            onClick={onClick}
            className={classNames(
                className,
                styles['CardEvent'],
                {
                    [styles['CardEvent--Shown']]: noEffect,
                    [styles['CardEvent--Loaded']]: inView && !noEffect,
                    [styles['CardEvent--Horizontal']]: isHorizontal,
                    [styles['CardEvent--Loader']]: isLoader,
                },
                modifiers.map((mod) => styles['CardEvent--' + mod])
            )}>
            {!!href && (
                <a
                    className={styles['CardEvent__Link']}
                    href={href}
                    {...extraProps}
                    tabIndex={tabIndex}>
                    <span className="sr-only">{label || title}</span>
                </a>
            )}
            <div
                className={classNames(
                    className,
                    styles['CardEventPicture'],
                    styles['CardEventPicture--' + ucFirst(size)]
                )}>
                {image && image.renditions && (
                    <div
                        className={classNames(
                            styles['CardEventPicture__Picture'],
                            styles[
                                'CardEventPicture__Picture--' + ucFirst(size)
                            ]
                        )}>
                        {image && image.renditions && (
                            <LazyLoad offset={400} once>
                                <img
                                    sizes="343px"
                                    width={253}
                                    height={243}
                                    srcSet={`${image.renditions.medium.src} 600w,
                                     ${image.renditions.large.src} 1200w`}
                                    src={image.renditions.medium.src}
                                    alt={image.alt}
                                    style={{
                                        objectPosition:
                                            image.focal.x + ' ' + image.focal.y,
                                    }}
                                />
                            </LazyLoad>
                        )}

                        <div
                            className={
                                styles['CardEventPicture__PictureCategory']
                            }>
                            {!!category?.title && (
                                <div
                                    className={classNames(
                                        styles[
                                            'CardEventPicture__CategoryContainer'
                                        ]
                                    )}>
                                    <EventIcon icon={category.icon} />
                                    <span
                                        className={
                                            styles[
                                                'CardEventPicture__CategoryText'
                                            ]
                                        }>
                                        {category.title}
                                    </span>
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>

            {!showRemoveButton && isHorizontal && (
                <button
                    className={classNames(styles['CardEvent__Bookmark'], {
                        [styles['CardEvent__Bookmark--Saved']]: isSaved,
                    })}
                    aria-labelledby={`id_bookmark_${id}`}
                    onClick={handleSave}>
                    <span id={`id_bookmark_${id}`} className="sr-only">
                        {saveButtonText}
                    </span>
                    <Icon {...bookmarkIconType} size="xl" color={iconColor} />
                </button>
            )}
            <div className={styles['CardEvent__Content']}>
                {title && (
                    <h3 className={styles['CardEvent__Title']}>{title}</h3>
                )}

                {label && showRemoveButton && (
                    <p className={styles['CardEvent__Label']}>{label}</p>
                )}

                {description && !inSlider && (
                    <div className={styles['CardEvent__Description']}>
                        <p
                            dangerouslySetInnerHTML={{
                                __html: description,
                            }}></p>
                    </div>
                )}
                {showRemoveButton && (
                    <button
                        className={styles['CardEvent__RemoveButton']}
                        aria-label={i18n.t('generic.remove') + ': ' + title}
                        onClick={(e) => handleRemoveEvent(e)}>
                        <Icon
                            type="close"
                            size="small"
                            color="gray500"
                            modifiers={[styles['CardEvent__IconSize']]}
                        />
                        <div className={styles['CardEvent__RemoveText']}>
                            {i18n.t('generic.remove')}
                        </div>
                    </button>
                )}

                <div className={styles['CardEvent__Meta']}>
                    <div className={styles['CardEvent__MetaRow']}>
                        <div className={styles['CardEvent__IconWrapper']}>
                            <Icon
                                size="small"
                                type="calender"
                                color={iconColor}
                            />
                        </div>
                        {!!startDate && !!endDate && (
                            <span>
                                {isSameDate(startDate, endDate)
                                    ? startDate.toLocaleString(
                                          LOCALE,
                                          DATE_FORMAT
                                      )
                                    : [
                                          startDate.toLocaleDateString(
                                              LOCALE,
                                              DATE_FORMAT
                                          ),
                                          endDate.toLocaleDateString(
                                              LOCALE,
                                              DATE_FORMAT
                                          ),
                                      ].join(' - ')}
                            </span>
                        )}

                        {!!startTime && !!endTime && (
                            <span>
                                , {startTime.split(':').slice(0, 2).join(':')} -{' '}
                                {endTime.split(':').slice(0, 2).join(':')}
                            </span>
                        )}
                    </div>

                    {(venueName || address || isLoader) &&
                        !showRemoveButton && (
                            <div className={styles['CardEvent__MetaRow']}>
                                <div
                                    className={
                                        styles['CardEvent__IconWrapper'] +
                                        ' ' +
                                        styles['CardEvent__IconWrapper--Place']
                                    }>
                                    <Icon
                                        size="sm"
                                        type="place"
                                        color={iconColor}
                                    />
                                </div>
                                <span>{venueName ?? address}</span>
                            </div>
                        )}
                </div>
            </div>
        </div>
    );
};

CardEvent.propTypes = {
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    image: PropTypes.object,
    startDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
    endDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
    href: PropTypes.string,
    label: PropTypes.string,
    category: PropTypes.object,
    isLoader: PropTypes.bool,
    title: PropTypes.string,
    color: PropTypes.oneOf(['blue50', 'green50', 'purple50', 'orange50']),
    content: PropTypes.bool,
    onClick: PropTypes.func,
    onCardClick: PropTypes.func,
    address: PropTypes.string,
    description: PropTypes.string,
    modifiers: PropTypes.array,
    className: PropTypes.string,
    showRemoveButton: PropTypes.bool,
    removeEvent: PropTypes.func,
    setActive: PropTypes.func,
    startTime: PropTypes.string,
    endTime: PropTypes.string,
};

CardEvent.defaultProps = {
    id: null,
    size: 'small',
    image: null,
    date: null,
    startDate: null,
    endDate: null,
    label: null,
    href: null,
    title: null,
    category: null,
    color: 'blue50',
    content: false,
    onClick: () => {},
    address: null,
    description: null,
    modifiers: [],
    className: '',
    inSlider: false,
    showRemoveButton: false,
    removeEvent: () => {},
    setActive: () => {},
    startTime: null,
    endTime: null,
};

export default CardEvent;
